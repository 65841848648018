module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"globalScope":"\n          import ResponsiveEmbed from \"react-responsive-embed\";\n          import { TwitterTweetEmbed } from \"react-twitter-embed\";\n\n          export default { ResponsiveEmbed, TwitterTweetEmbed };\n        ","extensions":[".mdx",".md",".markdown"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"backgroundColor":"#fafafa","maxWidth":1035}}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/lib/typography"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-62568462-4"},
    }]
